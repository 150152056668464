.laila-light {
    font-family: "Laila", serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .laila-regular {
    font-family: "Laila", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .laila-medium {
    font-family: "Laila", serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .laila-bold {
    font-family: "Laila", serif;
    font-weight: 700;
    font-style: normal;
  }
  

  
.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  button:focus {
    outline: unset;
  }
  .shadow_btn{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
.mt-8rem{
  margin-top: 8rem;
}