*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body{
  height: 100%;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
a{
  text-decoration: none;
}

.top_banner_bg{
  background-image: url('../public/images/top_banner.png');
background-repeat: no-repeat;
background-size: cover;
}
/* NavBar CSS*/

nav {
  display: block;
}

.menu {
  /*display: block; -before*/
  display: flex;
}

.menu li {
  display: inline-block;
  position: relative;
  z-index: 100;
}

.menu li:first-child {
  margin-left: 0;
}

.menu li a {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  color: #000;
  text-decoration: none;
  padding: 10px 10px;
  display: block;
  color: #000;
  transition: all 0.2s ease-in-out 0s;
  font-family: poppins;
}

.menu li a:hover,.menu li:hover>a {
  color: #fff;
  background: #F39C11;
  /* margin-left: 1px; */
}

.menu ul {
  visibility: hidden;
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 170px;
  position: absolute;
  left: 0px;
  background: #fff;
  z-index: 99;
  transform: translate(0,20px);
  transition: all 0.2s ease-out;
  box-shadow: 20px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.menu ul:after {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 6px;
  margin-left: -6px;
}

.menu ul li {
  display: block;
  float: none;
  background: none;
  margin: 0;
  padding: 0;
}

.menu ul li a {
  font-size: 15px;
  font-weight: 500;
  display: block;
  color: #000;
  background: #fff;
  font-family: poppins;
}

.menu ul li a:hover,.menu ul li:hover>a {
  background: #F39C11;
  color: #fff;
}

.menu li:hover>ul {
  visibility: visible;
  opacity: 1;
  transform: translate(0,0);
}

.menu ul ul {
  left: 169px;
  top: 0px;
  visibility: hidden;
  opacity: 0;
  transform: translate(20px,20px);
  transition: all 0.2s ease-out;
}

.menu ul ul:after {
  left: -6px;
  top: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 6px;
  margin-top: -6px;


}

.menu li>ul ul:hover {
  visibility: visible;
  opacity: 1;
  transform: translate(0,0);
}

.responsive-menu {
  display: none;
  width: 100%;
  padding: 20px 15px;
 /* background: #374147;*/
  text-transform: uppercase;
  font-weight: 600;
}

.responsive-menu:hover {
  /* background: #374147; */
  color: #fff;
  text-decoration: none;
}

/* a.homer {
  background: #20242d;
} */

@media (min-width: 768px) and (max-width: 979px) {
  .mainWrap {
      width: 768px;
  }

  .menu ul {
      top: 37px;
  }

  .menu li a {
      font-size: 12px;
  }

  
}

@media (max-width: 767px) {
  .mainWrap {
      width: auto;
      padding: 50px 20px;
  }

  .menu {
      display: none;
  }

  .responsive-menu {
      display: block;
  }

  nav {
      margin: 0;
      background: none;
  }
  .sm-wd-100{
    width: 100%;
  }
  .menu li {
      display: block;
      margin: 0;
  }

  .menu li a {
      background: #fff;
      color: #797979;
  }

  .menu li a:hover,.menu li:hover>a {
      background: #F39C11;
      color: #fff;
  }

  .menu ul {
      visibility: hidden;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      transform: initial;
  }

  .menu li:hover>ul {
      visibility: visible;
      opacity: 1;
      position: relative;
      transform: initial;
  }

  .menu ul ul {
      left: 0;
      transform: initial;
  }

  .menu li>ul ul:hover {
      transform: initial;
  }
  .bar_icon{
    position: absolute;
    right: 0;
    font-size: 33px;
    top: -92%;
    color: #f39c11;
  }

  .responsive-menu{
    position: relative;
  }
}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  { 
   

    .mainWrap {
        width: auto;
        padding: 50px 20px;
    }
  
    .menu {
        display: none;
    }
  
    .responsive-menu {
        display: block;
    }
  
    nav {
        margin: 0;
        background: none;
    }
    .sm-wd-100{
      width: 100%;
    }
    .menu li {
        display: block;
        margin: 0;
    }
  
    .menu li a {
        background: #fff;
        color: #797979;
    }
  
    .menu li a:hover,.menu li:hover>a {
        background: #F39C11;
        color: #fff;
    }
  
    .menu ul {
        visibility: hidden;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        transform: initial;
    }
  
    .menu li:hover>ul {
        visibility: visible;
        opacity: 1;
        position: relative;
        transform: initial;
    }
  
    .menu ul ul {
        left: 0;
        transform: initial;
    }
  
    .menu li>ul ul:hover {
        transform: initial;
    }
    .bar_icon{
      position: absolute;
      right: 0;
      font-size: 33px;
      top: -92%;
      color: #f39c11;
    }
  
    .responsive-menu{
      position: relative;
    }
    .bar_icon {
      top: 0%;
  }
}
@media (max-width: 480px) {
}

@media (max-width: 320px) {

}
.menu_toggle{
	top: 294px;
    left: 10px;
}
.menu_active{
  display: block;
}

/*Navbar Css Ends*/

/* Header CSS*/
.contact_us_btn{
  background-color: #F39C11;
padding: 12px 17px 12px 17px;
border-radius: 32px;
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: left;
border: none;
color: white;
}
.contact_us_btn:hover{
  color: white;
}
/* Header CSS Ends */

.help_text{
  font-size: 14x;
  line-height: 21px;
  color: #F39C11;
}

/* Body container section 1 */
.welcome_text_left{
  font-family: Poppins;
font-size: 22px;
font-weight: 600;
line-height: 24px;
letter-spacing: -0.25px;
text-align: left;
color: #F39C11;
position: relative;
}

.welcome_text_left::before{
  background-color:#F39C11;
  height:12px;
  width:12px;
  border-radius:50%;
  position: absolute;
  content: '';
  top: 15%;
}

.where_Pets{
  font-family: 'Laila';
font-size: 55px;
font-weight: 700;
line-height: 58px;
text-align: left;
margin-top: 35px;

}

.where_pets_para{
font-family: 'Poppins';
font-size: 16px;
font-weight: 400;
line-height: 26px;
text-align: left;
margin-top: 20px;

}
.read_more_btn{
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  color: white;
  background-color: #F39C11;
  border: 1px solid #F39C11;
padding: 12px 29px 12px 29px;
border-radius: 50px;

}
.read_more_btn:hover{
  background-color: white;
  color: black;
  border: 1px solid black;
  /* transition: 0.3s; */
}
.contactus_top_btn{
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  color: black;
  background-color: white;
padding: 12px 29px 12px 29px;
border-radius: 50px;
border: 1px solid black;
}
.contactus_top_btn:hover{
color: white;
background-color: #F39C11;
border: 1px solid #F39C11;

}

/* sections 2 headings */
.section_headings{
  font-family: 'Poppins';
font-size: 22px;
font-weight: 600;
line-height: 24px;
text-align: center;
color: #F39C11;
position: relative;
}
.section_headings::after{
  background-color: #F39C11;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  content: '';
  top: 15%;
}

.section_headings::before{
  background-color: #F39C11;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  content: '';
  top: 15%;
}
.section_subheading{
  font-family: 'Laila';
font-size: 42px;
font-weight: 700;
text-align: center;
line-height: 60px;
}
.what_solving_box1{
  background-color: #F39C11;
  height: 359px;
  position: relative;
  border-radius: 24px;
}

.what_solving_para{
  font-family: 'Poppins';
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: #fff;
}
.what_solving_subpara{
  font-family: 'Poppins';
font-size: 14px;
font-weight: 500;
line-height: 22px;
text-align: center;
color: #fff;
}
.whatsolvingimg{
  position: absolute;
  top: -13%;
  /* right: 14%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.whatsolving_main{
  padding: 25px;
}

.pre-register-bg{
  background-image: url('../public/images/pre_register_bg.png');
  padding: 50px;
  /* position: absolute;
  bottom: 5%; */
}
.pre_register_heading{
  font-family: 'Laila';
  font-size: 42px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: white;
}

.pre_register_subheading{
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
  text-align: center;
color: white;
}

.enter_email_input{
padding: 2px 18px 2px 18px !important;
height:50px;
border-radius: 10px 0px 0px 10px !important;
border: 1px solid white;
background: none;
}
.enter_email_input_main{
  width:370px;
}
.register_btn{
  color: black;
  font-family: 'Poppins';
font-size: 13px;
font-weight: 600;
line-height: 19.5px;
text-align: center;
padding: 0px 40px;
background: white;
cursor: pointer;
}
.enter_email_input::placeholder {
  color: white !important;
  opacity: 1; /* Firefox */
  font-family: 'Poppins';
font-size: 14px;
font-weight: 400;
line-height: 45px;
text-align: left;

}

.enter_email_input::-ms-input-placeholder { /* Edge 12 -18 */
  color: white;
  opacity: 1; /* Firefox */
  font-family: 'Poppins';
font-size: 14px;
font-weight: 400;
line-height: 45px;
text-align: left;
}

.mr-img{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 950px;
}
.font-18{
  font-size: 18px;
  text-align: left;
}
.font-32{
  font-size: 32px;
  text-align: left;
}

.connected_list{
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
}
.petchat-bg{
  background-color: #F39C11;
  border-radius: 15px;
  padding: 40px;
}
.have_query{
  font-family: 'Laila';
font-size: 42px;
font-weight: 700;
line-height: 45px;
text-align: left;
color: white;
margin-top: 20px;
}
.write_to_us{
  font-family: 'Poppins';
font-size: 18px;
font-weight: 600;
line-height: 45px;
letter-spacing: -0.25px;
text-align: left;
color: white;
}
.call_us{
  font-family: 'Poppins';
font-size: 18px;
font-weight: 700;
line-height: 45px;
text-align: left;
color: white;
margin-top: 23px;
text-decoration: underline;
cursor: pointer;
}

.petochat_btn{
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.petochat_btn:hover{
  border: none;
  background-color: white;
  color: black;
}

/* footer */

.footer_bg{
  background-image: url('../public/images/footer-bg.png');
  background-repeat: no-repeat;
  background-size: cover;

}
.footer_para{
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 22px;
    text-align: left;
}
.follow_us{
  font-family: 'Poppins';
font-size: 18px;
font-weight: 600;
line-height: 27px;
text-align: left;
color: #000;

}

.footer_links{
  font-family: 'Poppins';
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
color: #000;
margin-bottom: 10px;
pointer-events: none;
  cursor: default; 
}
.footer-head{
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.footer-black-bg{
  background-color: #000;
  padding: 30px;
}

.copyright_text{
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: white;
}
.pb-5rem{
  padding-bottom: 5rem;
}
.enter_email_input_bg{
  background-color: white;
}

#subscribeNewsletter input::placeholder{
  color:black !important;
}
.subscribe_btn{
  border-radius: 8px;
  background-color: #F39C11;
  font-family: Poppins;
font-size: 13px;
font-weight: 600;
line-height: 19.5px;
color: #fff;
padding: 12px 26px 12px 26px;
border: 1px solid #F39C11;
width: 100%;
}

.enter_email_input::placeholder {
  color: white !important;
  opacity: 1; /* Firefox */
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 45px;
  text-align: left;
}

.enter_email_input_have{
  padding: 2px 18px 2px 18px !important;
  height: 50px;
  border-radius: 10px 10px 10px 10px !important;
  border: 1px solid white;
  background: none;
}

#haveAnyQuery input::placeholder{
  color: white !important;
}
.send_enquiry_btn{
  border-radius: 8px;
  background-color: white;
  font-family: Poppins;
font-size: 13px;
font-weight: 600;
line-height: 19.5px;
color: black;
padding: 12px 26px 12px 26px;
border: 1px solid #F39C11;
width: 100%;
}


  .progress-bars {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: #F39C11;
    transform-origin: 0%;
    z-index: 999;
  }
  
  .footer_social_icons{
    background: black;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
  }
  .footer_social_icons i{
    color: white;
    font-size: 18px !important;
  }
a:hover{
color: black;
}
.footer_links:hover{
  color: #df9114;
}