@media (max-width: 480px) {
}

@media (max-width: 320px) {
}
@media (max-width: 767px) {
  .justify-end-sm {
    justify-content: end !important;
  }
  .justify-center-sm {
    justify-content: center !important;
  }
  .welcome_text_left {
    text-align: center;
  }
  .where_Pets {
    font-size: 45px;
    line-height: 55px;
    text-align: center;
  }
  .where_pets_para {
    text-align: center;
  }
  .sm-flex{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pre_register_subheading {
    line-height: 23px;
    margin-top: 20px;
  }
  .section_subheading {
    line-height: 42px;
    margin-top: 16px;
    }
    .font-18 {
        text-align: center;
    }
    .sm-mb{
        margin-bottom: 1rem !important;
    }
    .font-32 {
        font-size: 29px;
    }
    .section_headings{
        margin-top: 15px;
    }
    .sm-mt-2{
        margin-top: 2rem;
    }
    .write_to_us {
        line-height: 28px;
        margin-top: 15px;
    }
    .sm-mt-1{
        margin-top: 1rem !important;
    }
    .pre-register-bg {
      padding: 65px 50px;
    }
    
}

@media (min-width: 768px) and (max-width: 979px) {
    .font-32 {
        font-size: 29px;
    }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  .footer_links {
    font-family: 'Poppins';
    font-size: 12px;
}
.what_solving_subpara {
    font-size: 13px;
    line-height: 20px;
}
.what_solving_box1 {
    height: 385px;
}
}
